import React, { useState } from "react"
import "./Application.sass"
import { Button, Card, FormGroup, H3, NumericInput } from "@blueprintjs/core"

const Application = () => {
  const [x, setX] = useState(100)
  const [y, setY] = useState(100)
  const [loading, setLoading] = useState(false)

  const send = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)

    const url = "https://antboss-server.onrender.com/move-xy"

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ x, y })
    })
    console.info("move-xy resp", resp)

    setLoading(false)
  }


  return (
    <div className={"application"}>
      <Card className={"send-xy"} elevation={2}>
        <form onSubmit={send}>
          <H3>Move XY</H3>
          <FormGroup label={"x"}>
            <NumericInput large value={x} disabled={loading} onValueChange={setX}/>
          </FormGroup>
          <FormGroup label={"y"}>
            <NumericInput large value={y} disabled={loading} onValueChange={setY}/>
          </FormGroup>
          <FormGroup>
            <Button fill large text={"Send"} intent={"primary"} loading={loading} type={"submit"}/>
          </FormGroup>
        </form>
      </Card>
    </div>
  )
}

export default Application
